import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Modal, Table, Button } from "react-bootstrap";
import Footer from "../layout/Footer";
import PageTitle from '../layout/PageTitle';
import { postToAPI,getFromAPI,mediaBaseUrl, putToAPI } from '../Utils/utils.jsx'; // Import utility functions

//Data-table
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import 'datatables.net-dt/css/jquery.dataTables.css';
import ComplaintsListTableData from "./dashboard_data.json";
import 'jquery-ui-dist/jquery-ui.css'; // jQuery UI CSS for date picker
import 'jquery-ui-dist/jquery-ui.js';

// img
import ComplanintImg1 from '../../images/light-img-1.jpg';
import ComplanintImg2 from '../../images/light-img-2.jpg';
import ComplanintImg3 from '../../images/light-img-3.jpg';
import { toast } from "react-toastify";

export default function Dashboard() {

  // View Complaints modal
  const [complaintModal, setComplaintModal] = useState(false);
  const complaintModalClose = () => {
    setDisableWard(true);
    setDisableSV(true);
    setValidated(false);
    setComplaintModal(false);
    UpdateComplaintDataTable();
  }
  const [complaintsInputData, setComplaintsInputData] = useState({});
  
  // Close and show functions for modal
  const complaintModalShow = (complaintId) => {
    const selectedComplaint = complaintData.find(complaint => parseInt(complaint.id) === parseInt(complaintId));
    if(selectedComplaint) {
      const ward = wardData.find(w => parseInt(w.id) === parseInt(selectedComplaint.wardId));
      setSelectedZone(ward.zone.id);
    }
    setComplaintsInputData(selectedComplaint || {});
    setComplaintModal(true);
  };
  
  // Media base URL

  const [complaintData, setComplaintData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [supervisorData, setSupervisorData] = useState([]);
  const [citizenData,setCitizenData] = useState([])
  const [remarkCitizen,setRemarkCitizen] = useState([])
  const [remarkSupervisor,setRemarkSupervisor] = useState([])
  const [attachmentData,setAttachment] = useState([])
  const [filterData, setFilterData] = useState(complaintData);
  const [zoneData, setzoneData] = useState();
  const [wardData, setwardData] = useState();
  const [filteredWards, setfilteredWards] = useState();
  const [filteredSupervisors, setfilteredSupervisors] = useState();
  const [DisableWard, setDisableWard] = useState(true);
  const [DisableSV, setDisableSV] = useState(true);
  const [SelectedZone, setSelectedZone] = useState();
  const [alldata,setAll] = useState(0);
  const [completData,setCompleteData] = useState(0);
  const [pendingData,setPendingData] = useState();
  const [inProgressData,setInProgressData] = useState(0);
  const [assignedData,setAssignedData] = useState(0);
  const [complaintsStatusData, setComplaintsStatusData] = useState([]);
  // Form validation
  const [validated, setValidated] = useState(false);

  // Update filterData when complaintData changes
  useEffect(() => {
    setFilterData(complaintData);
  }, [complaintData]); // Dependency array includes complaintData

  useEffect(() => {
    fetchSupervisor();
    fetchCitizen();
    fetchCategory();
    fetchWard();
    fetchZone();
    fetchAttachemnet();
    fetchRemark();
    fetchComplaint();
  }, []);

    const fetchCategory = async () => {
      try {
        const response = await getFromAPI("category/");
        setCategoryData(response);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };


    const fetchCitizen = async () => {
      try {
        const response = await getFromAPI("citizen/");
        if (response && response.status) {
          setCitizenData(response.citizens);
        } else {
          console.error("API response is missing status or data");
        }      } catch (error) {
        console.error("Error fetching citizen:", error);
      }
    };

    const fetchSupervisor = async () => {
      try {
        const response = await getFromAPI("Supervisor/");
          setSupervisorData(response);
          setfilteredSupervisors(response);
        
      } catch (error) {
        console.error("Error fetching supervisor:", error);
      }
    };

    const fetchWard = async () => {
      try {
        const response = await getFromAPI("ward/");
        setwardData(response.ward);
        setfilteredWards(response.ward);
      } catch (error) {
        console.error("Error fetching ward:", error);
      }
    };

    const fetchZone = async () => {
      try {
        const response = await getFromAPI("zone/");
        setzoneData(response);
      } catch (error) {
        console.error("Error fetching zone:", error);
      }
    };

    const fetchComplaint = async () => {
      try {
        const response = await getFromAPI("complaint/");
        setComplaintData(response);
      } catch (error) {
        console.log("Error fetching complaints:", error);
      }
    };
    
    const fetchAttachemnet = async() => {
      try {
        const response = await getFromAPI("attachment/");
        setAttachment(response);
      } catch (error) {
        console.log("Error fetching complaints:", error);
      }
    }; 

    const fetchRemark = async() => {
      try {
        const response = await getFromAPI("remark/");
        setRemarkCitizen(response);
      } catch (error) {
        console.log("Error fetching complaints:", error);
      }
    }; 

  function formatDateTime(dateTimeString) {
    if (dateTimeString === null || dateTimeString === undefined) {
      return '';
    }
    return dateTimeString.replace('T', ' | ').replace('Z', '').slice(0, 18);
  }

  const getCategoryNameById = (id) => {
    const category = categoryData.find(cat => cat.id === id);
    return category ? category.name : '';
  };

  const getRemarkNameById = (Complaintid, userID) => {
    const category = remarkCitizen.find(cat => cat.complaintId === Complaintid && cat.userId === userID);
    return category ? category.remark : '';
  };

  const getRemarkFromSupervisorNameById = (id) => {
    const category = remarkCitizen.find(cat => cat.userId === id);
    return category ? category.remark : '';
  };

  const getSupervisorNameById = (id) => {
    const category = supervisorData.find(cat => cat.id === id);

    return category ? category.FirstName + " " + category.LastName : '';
  };


  const getCitizenNameById = (id) => {
    const citizen = citizenData.find(cit => cit.id === id);
    return citizen ? citizen.FirstName + " "  + citizen.LastName: ''; // Return FirstName or fallback
  };

  const getCitizenEmailById = (id) => {
    const citizen = citizenData.find(cit => cit.id === id);
    return citizen ? citizen.user_data.email : ''; // Return FirstName or fallback
  };

  const getCitizenMobileNoById = (id) => {
    const citizen = citizenData.find(cit => cit.id === id);
    return citizen ? citizen.user_data.mobileNo : ''; // Return FirstName or fallback const citizen = citizenData.find(cit => cit.id === id);
  };
  
 const getAttachmentsByComplaintId = (complaintId, userId) => {
  // Filter the attachments by complaintId and build the full URL
  return attachmentData
    .filter(att => att.complaintId === complaintId && att.userId === userId)
    .map(att => ({
      ...att,
      fullUrl: `${mediaBaseUrl}${att.attachment}` // Concatenating mediaBaseUrl with attachment path
    }));

};

const getAttachmentsBySupervisorComplaintId = (complaintId, supervisorId) => {
  // Filter the attachments by complaintId and build the full URL
  return attachmentData
    .filter(att => att.UserId === complaintId && att.userId === supervisorId)
    .map(att => ({
      ...att,
      fullUrl: `${mediaBaseUrl}${att.attachment}` // Concatenating mediaBaseUrl with attachment path
    }));

};

  // Function to calculate complaints titles based on status
  const calculateComplaintsByStatus = () => {
    const totalComplaints = complaintData.length;
    setAll(totalComplaints)
    const inProgress = complaintData.filter(complaint => complaint.status === 'in-progress').length;
    setInProgressData(inProgress)
    const assigned = complaintData.filter(complaint => complaint.status === 'assigned').length;
    setAssignedData(assigned)
    const pending = complaintData.filter(complaint => complaint.status === 'Pending').length;
    setPendingData(pending)
    const completed = complaintData.filter(complaint => complaint.status === 'complete').length;
    setCompleteData(completed)

    // Update the complaintsTitle dynamically
    const updatedData = complaintsStatusData.map(data => {
      switch (data.complaintsTitleText) {
        case "All Complaints":
          return { ...data, complaintsTitle: totalComplaints };
        case "In Progress Complaints":
          return { ...data, complaintsTitle: inProgress };
        case "Assigned Complaints":
          return { ...data, complaintsTitle: assigned };
        case "Pending Complaints":
          return { ...data, complaintsTitle: pending };
        case "Completed Complaints":
          return { ...data, complaintsTitle: completed };
        default:
          return data;
      }
    });

    setComplaintsStatusData(updatedData);
  };

  useEffect(() => {
    calculateComplaintsByStatus();
  }, [complaintData]);

  const [selectedSubTitle, setSelectedSubTitle] = useState('All Complaints');
  const [selectedCard, setSelectedCard] = useState(complaintsStatusData[0]?.complaintsTitleText);

const getStatusFromSubtitle = () => {
  let response;
  switch (selectedSubTitle.complaintsTitleText) {
    case "Completed Complaints":
      response = `complaint/?status=Complete`;
      break;
    case "Pending Complaints":
      response = `complaint/?status=Pending`;
      break;
    case "Assigned Complaints":
      response = `complaint/?status=Assigned`;
      break;
    case "In Progress Complaints":
      response = `complaint/?status=In-Progress`;
      break;
    default:
      response = `complaint/`;
      break;
  }

  return response;
}

 // Function to fetch data from API
 const fetchComplaintData = async (subTitle) => {
  try {
    // Fetch complaint data from the API based on subtitle
    let response;
    switch (subTitle.complaintsTitleText) {
      case "Completed":
        response = await getFromAPI(`complaint/?status=complete`);
        break;
      case "Pending":
        response = await getFromAPI(`complaint/?status=Pending`);
        break;
      case "Assigned":
        response = await getFromAPI(`complaint/?status=Assigned`);
        break;
      case "In Progress":
        response = await getFromAPI(`complaint/?status=In-Progress`);
        break;
      default:
        response = await getFromAPI(`complaint/`);
        break;
    }
    setFilterData(response); // Update state with fetched data
  } catch (error) {
    console.error("Error fetching complaint data:", error);
  }
};

// Handle card click
const handleCardClick = async (subTitle) => {
  setSelectedCard(subTitle.complaintsTitleText);
  setSelectedSubTitle(subTitle);
  // Fetch data based on selected card
  await fetchComplaintData(subTitle);
};

const handleZoneSelectChange = (e) => {
  const SelectedZone = e.target.value;
  const ZoneWards = wardData.filter((ward)=> parseInt(ward.zone.id) === parseInt(SelectedZone));
  setSelectedZone(SelectedZone);
  setfilteredWards(ZoneWards);
  if(ZoneWards.length === 0) setDisableWard(true);
  else setDisableWard(false);
  setDisableSV(true);
  setfilteredSupervisors(null);
}

const handleWardSelectChange = (e) => {
  const Selectedward = e.target.value;
  setComplaintsInputData({...complaintsInputData, wardId: parseInt(Selectedward) })
  const WardsSupervisor = supervisorData.filter((sv)=> sv.wardId.some((ward)=>parseInt(ward) === parseInt(Selectedward)));
  setfilteredSupervisors(WardsSupervisor);
  if(WardsSupervisor.length === 0) setDisableSV(true);
  else setDisableSV(false);
}

const handleEditComplaint = async (e) => {
  e.preventDefault();
  e.stopPropagation();
  try {
    const Response = await putToAPI(`complaint/${complaintsInputData.id}/`, complaintsInputData);
    if (Response.status) {
        toast.success('Complaint Successfully updated');
        // UpdateZoneDataTable();
        complaintModalClose();
    } else {
        toast.error('Failed to update complaint');
    }
  } catch (error) {
      console.error('Error:', error);
      toast.error('An unexpected error occurred');
  }
}

// Initialize or update DataTable
DataTable.Buttons.jszip(JSZip);
pdfMake.vfs = pdfFonts.pdfMake.vfs;
useEffect(() => {
  // Destroy any existing DataTable instance
  if ($.fn.dataTable.isDataTable("#maindatatable")) {
    $("#maindatatable").DataTable().clear().destroy();
  }

  // Initialize DataTable
  $("#maindatatable").DataTable({
    dom: '<"date-filter" <"from-date"> <"to-date">>B<"top"lf>rtip',
    buttons: [
      {
        extend: 'excel',
        className: 'btn btn-export btn-md btn-lightdark',
        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
        exportOptions: {
          columns: function (index) {
            return index !== 9; // Exclude the Action column (index 9)
          },
        },
      },
      {
        extend: 'pdf',
        className: 'btn btn-export btn-md btn-lightdark',
        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to PDF',
        exportOptions: {
          columns: function (index) {
            return index !== 9; // Exclude the Action column (index 9)
          },
        },
        
        customize: function (doc) {
          // Set table alignment to center
          doc.content[1].table.body.forEach(function (row) {
            row.forEach(function (cell) {
              cell.alignment = 'center'; // Apply center alignment to all cells
              cell.border = [true, true, true, true];
            });
            doc.content[1].layout = {
              hLineWidth: function (i, node) {
                  return 1;
              },
              vLineWidth: function (i, node) {
                  return 1;
              },
              hLineColor: function (i, node) {
                  return '#000000'; // Border color for horizontal lines
              },
              vLineColor: function (i, node) {
                  return '#000000'; // Border color for vertical lines
              },
              paddingLeft: function (i, node) { return 4; }, // Padding inside the cells
              paddingRight: function (i, node) { return 4; },
              paddingTop: function (i, node) { return 4; },
              paddingBottom: function (i, node) { return 4; }
          };

          doc.styles.tableHeader.fontSize = 10;
          doc.defaultStyle.fontSize = 10;
        });
        },
      },
      {
        extend: 'print',
        className: 'btn btn-export btn-md btn-lightdark',
        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
        exportOptions: {
          columns: function (index) {
            return index !== 9; // Exclude the Action column (index 9)
          },
        },
        customize: function (win) {
          $(win.document.body).find('h1').remove(); // Removes the title from the print view
          $(win.document.body).find('table').addClass('display').css('font-size', '10px');
          $(win.document.body).find('table').css('width', '100%');
        }
    },
    ],
    data: filterData.length > 0 ? filterData.map((data, i) => [
      i + 1,
      data.complaint_Number,
      getCategoryNameById(data.complaintCategoryId),
      getCitizenNameById(data.userId),
      getCitizenMobileNoById(data.userId),
      formatDateTime(data.complaintCreationDate),
      formatDateTime(data.ComplaintCompletionDateTime),
      getSupervisorNameById(data.supervisorId),
      data.status,
      data.id 
    ]) : [], // Provide an empty array if there's no data
    columns: [
      { title: "No." },
      { title: "Complaint ID" },
      { title: "Type" },
      { title: "Citizen Name" },
      { title: "Mobile number" },
      { title: "Registered Date/Time" },
      { title: "Completed Date/Time" },
      { title: "Assigned to" },
      {
        title: "Status",
        render: function (data, type, row) {
          const status = row[8];
          const GetStatusColorFromStatus = (status) => {
            switch (status) {
              case 'complete':
                return "success";
              case 'in-progress':
                return "primary";
              case 'assigned':
                return "warning";
              case 'Pending':
                return "danger";
            }
          }
        let statusColor = GetStatusColorFromStatus(status);
        return `<span class="badge badge-soft-${statusColor}">${status}</span>`;
        }
      },
      {
        title: "Action",
        render: function (data, type, row) {
          return `<button class="btn btn-light btn-sm view-complaint" data-compl-id="${row[9]}">View Info</button>`;
        }
      },
    ],
    autoWidth: false,
    language: {
      search: "",
      searchPlaceholder: "Search...",
      paginate: {
        previous: "«",
        next: "»",
      },
      emptyTable: "No data available" // Ensure this is set to handle empty data
    },
  });

  // Event delegation to handle view action
  $(document).on('click', '.view-complaint', function () {
    const complaintId = $(this).data('compl-id');
    complaintModalShow(complaintId);
  });

  // Initialize date pickers
  $("div.from-date").html(`
    <label>Start Date: 
      <div class="date-input-with-icon">
        <input type="text" id="min-date" placeholder="YYYY-MM-DD" class="datepicker form-control" />
        <i class="bi bi-calendar date-input-icon"></i>
      </div>
    </label>
  `);
  $("div.to-date").html(`
    <label>End Date: 
      <div class="date-input-with-icon">
        <input type="text" id="max-date" placeholder="YYYY-MM-DD" class="datepicker form-control" />
        <i class="bi bi-calendar date-input-icon"></i>
      </div>
    </label>
  `);

  // Initialize jQuery UI date pickers
  $(".datepicker").datepicker({
    dateFormat: 'yy-mm-dd', // Adjust date format to match your data format
    changeMonth: true,
    changeYear: true,
    onSelect: function () {
      $("#maindatatable").DataTable().draw(); // Redraw the DataTable when the date is selected
    },
  });

  let toastShown = false; // Initialize flag outside of the function

  $.fn.dataTable.ext.search.push(function (settings, data) {
    const minDate = $('#min-date').val();
    const maxDate = $('#max-date').val();
    
    const min = minDate ? new Date(minDate).getTime() : NaN;
    const max = maxDate ? new Date(maxDate).getTime() : NaN;

    const dateStr = data[5];  // Date column (index 3)
        
    // Extract the date part before the '|' character (removing time)
    const [datePart] = dateStr.split('|');
    const trimmedDatePart = datePart.trim();
    
    // Parse the date
    const date = new Date(trimmedDatePart).getTime();
    
    if (isNaN(date)) {
      console.error("Failed to parse date:", trimmedDatePart);
    }
      // Check for invalid date range
      if (!isNaN(min) && !isNaN(max) && min > max) {
        if (!toastShown) {
          toast.error("Start date cannot be greater than end date.");
          toastShown = true; // Set flag to avoid showing the toast again
        }
        return false; // Do not show data if the date range is invalid
      } else {
        toastShown = false; // Reset the flag if dates are valid
      }
  
    if (
      (isNaN(min) && isNaN(max)) ||
      (isNaN(min) && date <= max) ||
      (min <= date && isNaN(max)) ||
      (min <= date && date <= max)
    ) {
      return true;
    }
    return false;
  });
  
  // Trigger redraw on date input change
  document.querySelectorAll('#min-date, #max-date').forEach((el) => {
    el.addEventListener('change', () => $("#maindatatable").DataTable().draw());
  });

  // Cleanup function to destroy DataTable
  return () => {
    if ($.fn.dataTable.isDataTable("#maindatatable")) {
      $("#maindatatable").DataTable().destroy();
    }
    $.fn.dataTable.ext.search.pop();
  };
}, [filterData]);

const UpdateComplaintDataTable = () => {
  const MainDT = $('#maindatatable').DataTable();
  let url = getStatusFromSubtitle();
  getFromAPI(url)
      .then(response => {
          // setComplaintTypesData(response);
          let DTData = response.map((data, i) => [
            i + 1,
            data.complaint_Number,
            getCategoryNameById(data.complaintCategoryId),
            getCitizenNameById(data.userId),
            getCitizenMobileNoById(data.userId),
            formatDateTime(data.complaintCreationDate),
            formatDateTime(data.ComplaintCompletionDateTime),
            getSupervisorNameById(data.supervisorId),
            data.status,
            data.id 
          ]);
          MainDT.clear().rows.add(DTData).draw();
      })
      .catch(error => {
      console.error('Error:', error);
      });
}

useEffect (() =>{
const ComplaintsStatusData = [
  {
    id: 1,
    complaintsColor: "widgets-dark",
    complaintsIcon: "bi-list-ul",
    complaintsTitle: alldata, // Initially 0, will be updated dynamically
    complaintsTitleText: "All",
    complaintsSubTitle: "Complaints"
  },
  {
    id: 2,
    complaintsColor: "widgets-primary",
    complaintsIcon: "bi-ui-checks",
    complaintsTitle: inProgressData,
    complaintsTitleText: "In Progress",
    complaintsSubTitle: "Complaints"
  },
  {
    id: 3,
    complaintsColor: "widgets-warning",
    complaintsIcon: "bi-person-check-fill",
    complaintsTitle: assignedData,
    complaintsTitleText: "Assigned",
    complaintsSubTitle: "Complaints"
  },
  {
    id: 4,
    complaintsColor: "widgets-danger",
    complaintsIcon: "bi-clock-history",
    complaintsTitle: pendingData,
    complaintsTitleText: "Pending",
    complaintsSubTitle: "Complaints"
  },
  {
    id: 5,
    complaintsColor: "widgets-success",
    complaintsIcon: "bi-check2-circle",
    complaintsTitle: completData,
    complaintsTitleText: "Completed",
    complaintsSubTitle: "Complaints"
  }
];
setComplaintsStatusData(ComplaintsStatusData)
},[alldata,pendingData,completData,assignedData]);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Dashboard" />

          <Container>
          <Row xs={1} sm={2} md={3} xl={5} className="justify-content-center">
              {complaintsStatusData.map((data, i) => (
                <Col className="mb-4" key={i}>
                  <span
                    className={`card-link ${selectedCard === data.complaintsTitleText ? 'selected' : ''}`}
                    onClick={() => handleCardClick(data)}
                  >
                    <Card className={`mb-0 h-100 widgets-card ${data.complaintsColor} ${selectedCard === data.complaintsTitleText ? 'widgets-active' : ''}`}>
                      <Card.Body>
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-xl">
                            <i className={`bi ${data.complaintsIcon}`}></i>
                          </div>
                          <div className="ms-3">
                            <h2 className="widgets-title fw-bold d-flex flex-wrap align-items-center mb-0">{data.complaintsTitle}<span className="fs-20 ms-2 fw-medium">{data.complaintsTitleText}</span></h2>
                            {/* <p className="mb-0 text-muted">{data.complaintsSubTitle}</p> */}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </span>
                </Col>
              ))}
            </Row>

            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Complaints Details</Card.Title>
                    <Table responsive bordered className="mb-0 table-nowrap" id="maindatatable">
                      <thead className="table-light"></thead>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>

      {/* Complaint Details */}
      <Modal centered size="lg" show={complaintModal} onHide={complaintModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Complaint Details</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated} onSubmit={(e) => handleEditComplaint(e)}>
          <Modal.Body>
            <Row>
              <Col lg={3}>
                <div className="form-space">
                  <Form.Label>Complaint ID</Form.Label>
                  <Form.Control type="text" placeholder="Complaint ID" defaultValue={complaintsInputData.complaint_Number} disabled />
                </div>
              </Col>
              <Col lg={9}>
                <div className="form-space">
                  <Form.Label>Complaint Type</Form.Label>
                  <Form.Control type="text" placeholder="Complaint Type"   value={getCategoryNameById(complaintsInputData.complaintCategoryId) || ''}  disabled />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-space">
                  <Form.Label>Registered Date/Time</Form.Label>
                  <Form.Control type="text" placeholder="Date/Time" defaultValue={formatDateTime(complaintsInputData.complaintCreationDate)} disabled />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-space">
                  <Form.Label>Completed Date/Time</Form.Label>
                  <Form.Control type="text" placeholder="Date/Time" defaultValue={formatDateTime(complaintsInputData.ComplaintCompletionDateTime)} disabled />
                </div>
              </Col>
              <Col lg={6} >
                <div className="form-space">
                  <Form.Label>Select Zone</Form.Label>
                  <Form.Select value={SelectedZone} onChange={(e) => handleZoneSelectChange(e)}>
                      <option>Select Zone</option>
                      {zoneData && zoneData.map((zone) => (
                          <option key={zone.id} value={zone.id}>
                              {zone.name}
                          </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>
              <Col lg={6} >
                <div className="form-space">
                  <Form.Label>Select Ward</Form.Label>
                  <Form.Select value={complaintsInputData.wardId} onChange={(e) => handleWardSelectChange(e)} disabled={DisableWard} >
                      <option>Select Ward</option>
                      {filteredWards && filteredWards.map((ward) => (
                          <option key={ward.id} value={ward.id}>
                              {ward.name}
                          </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>
              <Col lg={9}>
                <div className="form-space">
                  <Form.Label>Assigned to</Form.Label>
                  <Form.Select value={complaintsInputData.supervisorId} onChange={(e) => setComplaintsInputData({...complaintsInputData, supervisorId: parseInt(e.target.value) })} disabled={DisableSV} >
                      <option>Select Supervisor</option>
                      {filteredSupervisors && filteredSupervisors.map((sv) => (
                          <option key={sv.id} value={sv.id}>
                              {sv.FirstName + " " + sv.LastName}
                          </option>
                      ))}
                  </Form.Select>
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-space">
                  <Form.Label>Status</Form.Label>
                  <Form.Control type="text" placeholder="Status" defaultValue={complaintsInputData.status} disabled />
                </div>
              </Col>
              <Col lg={12}>
                <div className="form-space">
                  <Form.Label>Citizen Name</Form.Label>
                  <Form.Control type="text" placeholder="Name" defaultValue={getCitizenNameById(complaintsInputData.userId)} disabled />
                </div>
              </Col>
              <Col lg={7}>
                <div className="form-space">
                  <Form.Label>Citizen Email</Form.Label>
                  <Form.Control type="email" placeholder="Email" defaultValue={getCitizenEmailById(complaintsInputData.userId)} disabled />
                </div>
              </Col>
              <Col lg={5}>
                <div className="form-space">
                  <Form.Label>Citizen Mobile Number</Form.Label>
                  <Form.Control type="text" placeholder="Mobile Number" defaultValue={getCitizenMobileNoById(complaintsInputData.userId)} disabled />
                </div>
              </Col>
              <Col lg={12}>
                <div className="form-space">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={6} placeholder="Description" defaultValue={complaintsInputData.description} disabled />
                </div>
              </Col>
              <Col lg={12}>
                <div className="form-space">
                  <Form.Label>Images Upload By Citizen</Form.Label>
                  <div className="input-box">
                    <Row className="g-2 g-sm-3">
                      {getAttachmentsByComplaintId(complaintsInputData.id, complaintsInputData.userId).map((attachment, attIndex) => (
                        <img
                          key={attIndex}
                          src={attachment.fullUrl}
                          alt={`Attachment ${attIndex + 1}`}
                          style={{ width: "100px", height: "100px", marginRight: "10px" }}
                        />
                      ))}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="form-space">
                  <Form.Label>Remarks Citizen</Form.Label>
                  <Form.Control type="text" placeholder="Remarks Citizen" defaultValue={getRemarkFromSupervisorNameById(complaintsInputData.id)} disabled />
                </div>
              </Col>
              <Col lg={12}>
                <div className="form-space">
                  <Form.Label>Images Upload By Supervisor</Form.Label>
                  <div className="input-box">
                    <Row className="g-2 g-sm-3">
                    {supervisorData.length > 0 && getAttachmentsByComplaintId(complaintsInputData.id, complaintsInputData.supervisorId).map((attachment, attIndex) => (
                        <img
                          key={attIndex}
                          src={attachment.fullUrl}
                          alt={`Attachment ${attIndex + 1}`}
                          style={{ width: "100px", height: "100px", marginRight: "10px" }}
                        />
                      ))}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div>
                  <Form.Label>Remarks Supervisor</Form.Label>
                  <Form.Control type="text" placeholder="Remarks Supervisor" defaultValue={getRemarkNameById(complaintsInputData.id, complaintsInputData.supervisorId)} disabled />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="mt-1">
              <Button type="submit" variant="primary">Save</Button>
              <Button variant="light" onClick={complaintModalClose}>Close</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}
