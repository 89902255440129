import React, { useState, useEffect } from "react";
import { Card, Button, Table, Form, Modal, Col, Row } from "react-bootstrap";
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { postToAPI, getFromAPI, putToAPI, deleteFromAPI } from "../Utils/utils";
import { toast } from "react-toastify";

export default function Wards() {
    // Modal
    const [showWardModal, setShowWardModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [wardData, setwardData] = useState();
    const [zonesData, setZonesData] = useState();
    const [supervisorData, setSupervisorData] = useState();

    // Form validation
    const [validated, setValidated] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [EditWardID, setEditWardID] = useState();
    const [DeleteWardID, setDeleteWardID] = useState();

    const [isDuplicateName, setIsDuplicateName] = useState(false);
    const [isDuplicateCode, setIsDuplicateCode] = useState(false);

    const [wardInputData, setwardInputData] = useState({
        name: "",
        zoneId: 0,
        code: "",   
    })

    const FetchWardData = () => {
        getFromAPI('ward/').then(data => {
            setwardData(data.ward);
        }).catch(error => {
            console.error('Error fetching Zone data:', error);
        });
    }

    const FetchAllZones = () => {
        getFromAPI('zone/').then(data => {
            setZonesData(data);
        }).catch(error => {
            console.error('Error fetching zones:', error);
        });
    }

    const fetchSupervisorData = () => {
        getFromAPI('supervisor/').then(data => {
            setSupervisorData(data.supervisors);
        }).catch(error => {
            console.error('Error fetching supervisors:', error);
        });
    }

    const handleNameChange = (e) => {
        let name_val = e.target.value.trim();
        setwardInputData({ ...wardInputData, name: name_val });
        const nameExists = wardData.some(ward => ward.name === name_val);
        setIsDuplicateName(nameExists);
    }

    const handleCodeChange = (e) => {
        let code_val = e.target.value.trim();
        setwardInputData({ ...wardInputData, code: code_val });
        const codeExists = wardData.some(ward => ward.code === code_val);
        setIsDuplicateCode(codeExists);
    }

    const handleAddWard = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
        } else {
            e.preventDefault(); // Prevent page reload
            setValidated(true);
            
            if(isEdit) {
                try {
                    const Response = await putToAPI(`ward/${EditWardID}/`, wardInputData);
                    if (Response.status) {
                        UpdateWardDataTable();
                        toast.success('Ward Successfully updated');
                        handleCloseWardModal();
                        
                    } else {
                        toast.error('Failed to update Ward');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            } else {
                if (isDuplicateName) {
                    toast.error('Ward name already exists');
                    return;
                }
                if (isDuplicateCode) {
                    toast.error('Ward code already exists');
                    return;
                } 
                try {
                    const Response = await postToAPI("ward/", wardInputData);
                    if (Response.status) {
                        UpdateWardDataTable();
                        toast.success('Ward Added Successfully');
                        handleCloseWardModal();
                        
                    } else {
                        toast.error('Failed to add Ward');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    toast.error('An unexpected error occurred');
                }
            }
        }
    }

    const handleEditWard = (title, ward_id) => {
        setIsEdit(true);
        setEditWardID(ward_id);
        getFromAPI(`ward/?id=${ward_id}`).then(response => {
            const ward_data = response.ward?.[0];
            if (ward_data) {
                setwardInputData({ name: ward_data.name || '', zoneId: ward_data.zone?.id || 0,  code: ward_data.code || '' });
                handleOpenWardModal(title);
            } else {
                console.error('Ward not found:', ward_id);
            }
        }).catch(error => {
            console.error('Error fetching ward:', error);
        });
    };
    
    const handleOpenWardModal = (title) => {
        setModalTitle(title);
        setShowWardModal(true);
    };

    const handleDeleteData = (id) => {
        // Set the selected id and show the modal
        setDeleteWardID(id);
        setShowDeleteModal(true);
    };

    const handleDeleteWard = async () => {
        // Check if this ward is assigned to supervisor or not
        let isAssignedWard = supervisorData.some((supervisor) =>
            supervisor.ward.some((ward) => parseInt(ward.id) === parseInt(DeleteWardID)));
        if(isAssignedWard) {
            toast.error('This ward is currently assigned to a supervisor. Please remove the ward from the supervisor’s assignment before attempting to delete it.');
        } else {
            try {
                const Response = await deleteFromAPI(`ward/${DeleteWardID}/`);
                if (Response.status) {
                    UpdateWardDataTable();
                    toast.success('Ward Successfully deleted');
                    handleCloseDeleteModal();
                    
                } else {
                    toast.error('Failed to delete Ward');
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An unexpected error occurred');
            }
        }
    }

    const handleCloseDeleteModal = () => {
        setDeleteWardID(null);
        setShowDeleteModal(false);
    }

    const handleCloseWardModal = () => {
        setIsEdit(false);
        setValidated(false);
        setShowWardModal(false);
        setEditWardID(null);
        setwardInputData({
            name: "",
            zoneId: 0,
            code: "", 
        });
    }

    // Datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (wardData && !$.fn.dataTable.isDataTable("#wardDataTable")) {
            $("#wardDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        },
                        customize: function (doc) {
                            // Set table layout to make the borders visible
                            doc.content[1].table.body.forEach(function(row) {
                                row.forEach(function(cell) {
                                    cell.border = [true, true, true, true]; // Top, left, bottom, right
                                });
                            });
                            doc.content[1].layout = {
                                hLineWidth: function (i, node) {
                                    return 1;
                                },
                                vLineWidth: function (i, node) {
                                    return 1;
                                },
                                hLineColor: function (i, node) {
                                    return '#000000'; // Border color for horizontal lines
                                },
                                vLineColor: function (i, node) {
                                    return '#000000'; // Border color for vertical lines
                                },
                                paddingLeft: function (i, node) { return 4; }, // Padding inside the cells
                                paddingRight: function (i, node) { return 4; },
                                paddingTop: function (i, node) { return 4; },
                                paddingBottom: function (i, node) { return 4; }
                            };

                            doc.styles.tableHeader.fontSize = 10;
                            doc.defaultStyle.fontSize = 10;
                        }
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' 
                        }
                    }
                ],
                data: wardData.map((data, i) => [
                    i + 1,
                    data.code,
                    data.name,
                    data.zone.name,
                    data.id
                    // data.supervisor  // No need for this column
                ]),
                columns: [
                    { title: "No." },
                    { title: "Ward Code" },
                    { title: "Ward Name" },
                    { title: "Zone" },
                    // { title: "Assigned Supervisor" },  // No need for this column
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2"><i class="bi bi-pencil-square edit-ward" data-ward-id="${row[4]}"></i></button>
                                    <button class="btn btn-soft-danger table-btn"><i class="bi bi-trash3 delete-ward" data-ward-id="${row[4]}"></i></button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                select: true,
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            document.querySelector('#wardDataTable tbody').addEventListener('click', function (event) {
                if (event.target && event.target.classList.contains('edit-ward')) {
                    const ward_id = event.target.getAttribute('data-ward-id');
                    handleEditWard("Edit Ward", ward_id);
                }
                if (event.target && event.target.classList.contains('delete-ward')) {
                    const ward_id = event.target.getAttribute('data-ward-id');
                    handleDeleteData(ward_id);
                }
            });

            // Zone filter logic
            $('#zoneFilter').on('change', function () {
                const selectedZone = $(this).val();
                $("#wardDataTable").DataTable().column(3).search(selectedZone).draw();  // Column index 3 is the "Zone" column
            });
        }
    }, [wardData]);

    const UpdateWardDataTable = () => {
        const PODT = $('#wardDataTable').DataTable();
        getFromAPI("ward/")
            .then(response => {
                setwardData(response.ward);
                let DTData = response.ward.map((data, i) => [
                    i + 1,
                    data.code,
                    data.name,
                    data.zone.name,
                    data.id
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(() => {
        FetchWardData();
        FetchAllZones();
        fetchSupervisorData();
    }, [])

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Wards</Card.Title>
                <Button variant="primary" onClick={() => handleOpenWardModal("Add Ward")}>
                    <i className="bi bi-plus-square me-2"></i> Add Ward
                </Button>
            </div>

            <div className="d-inline-flex gap-2 mb-3">
                {/* Zone Filter */}
                <Form.Select id="zoneFilter" aria-label="Default select example" size="md">
                    <option value="">Select Zone</option>
                    {zonesData && zonesData.map((zone) => (
                        <option key={zone.id} value={zone.name}>
                            {zone.name}
                        </option>
                    ))}
                </Form.Select>
            </div>

            <Table responsive bordered id="wardDataTable">
                <thead className="table-light"></thead>
            </Table>

            {/* Ward Modal */}
            <Modal centered size="lg" show={showWardModal} onHide={handleCloseWardModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleAddWard(e)}>
                    <Modal.Body className="pb-0">
                        <Row>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Ward Name</Form.Label>
                                    <Form.Control type="text" name="name" value={wardInputData.name} onChange={(e)=> handleNameChange(e)} required isInvalid={validated && isDuplicateName} />
                                    <Form.Control.Feedback type="invalid">
                                        {isDuplicateName ? "Ward name already exists." : "Please enter ward name."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={12}>
                                <Form.Group className="form-space">
                                    <Form.Label>Ward Code</Form.Label>
                                    <Form.Control type="text" name="name" value={wardInputData.code} onChange={(e)=> handleCodeChange(e)} required isInvalid={validated && isDuplicateCode} />
                                    <Form.Control.Feedback type="invalid">
                                        {isDuplicateCode ? "Ward code already exists." : "Please enter ward code."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}>
                                <Form.Group className="form-space">
                                    <Form.Label>Zone</Form.Label>
                                    <Form.Select as="select" name="zone" value={wardInputData.zoneId} onChange={(e)=>{setwardInputData({...wardInputData, zoneId:e.target.value})}} required isInvalid={validated && !wardInputData.zoneId} >
                                        <option value="">Select Zone</option>
                                        {zonesData && zonesData.map((zone) => (
                                            <option key={zone.id} value={zone.id}>
                                                {zone.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select zone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="mt-1">
                        <Button type="submit" variant="primary">Save</Button>
                        <Button variant="light" onClick={handleCloseWardModal}>Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={handleCloseDeleteModal}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleDeleteWard}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
