import React, { useState } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import LogoSm from "../images/logo-sm-white.png";
import { Link, useNavigate } from "react-router-dom";
import { postToAPI } from './Utils/utils.jsx'; // Import utility functions
import { toast, ToastContainer } from "react-toastify";

export default function OTPEntry() {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [resendDisabled, setResendDisabled] = useState(false);
  const navigate = useNavigate();
  const storedData = JSON.parse(localStorage.getItem('loginData'));
  const [inputData, setInputData] = useState({
    emailOrMobileNo: "",
    otp: "",
  });
  const handleOTPSubmit = async () => {
    // Combine OTP into a single string
    const otpCode = otp.join("");

    // Placeholder for OTP verification
    try {
      const response = await postToAPI("verify_otp/",
         { 
          emailOrMobileNo : storedData,
          otp: otpCode,         
        });
      if (response.status) {
        toast.success("OTP verified successfully.",'success');
        navigate("/set-new-password"); // Navigate to Set New Password page
      } else {
        toast.error('Invalid OTP','error');
      }
    } catch (error) {
      toast.error('Invalid OTP','error');
    }
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  const handleResendOTP = async () => {
    // Placeholder for resend OTP functionality
    setResendDisabled(true);
    setTimeout(() => setResendDisabled(false), 30000); // Re-enable after 30 seconds
    toast.success("OTP Resend to your email.",'success');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleOTPSubmit(); // Call OTP submit function
  };

  return (
    <>
      <div className="auth-bg py-4 py-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={8} lg={5} xxl={4}>
              <Card>
                <div className="auth-logo">
                  {/*<img src={LogoSm} alt="default" className="png-img-shadow rounded-circle" />*/}
                </div>
                <Link to="/forgot" className="avatar avatar-md bg-light text-dark rounded mt-2 ms-3">
                  <i class="bi bi-chevron-left fs-22"></i>
                </Link>
                <Card.Body className="pt-4">

                  {/* card text */}
                  <Card.Title className="text-center mt-3 mb-0">Enter Your Code</Card.Title>
                  <p className="text-center text-muted">We sent a code to {storedData}</p>

                  {/* Form implementation */}
                  <Form onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-center gap-2 mb-3 otp-inputs">
                      {otp.map((data, index) => (
                        <Form.Control
                          key={index}
                          type="text"
                          value={data}
                          onChange={(e) => handleChange(e, index)}
                          maxLength="1"
                          className="text-center"
                          required
                        />
                      ))}
                    </div>

                    <p className="text-center text-muted mt-3">
                      Didn’t receive the mail?{" "}
                      <span className="text-primary ms-2" onClick={handleResendOTP} disabled={resendDisabled}>
                        Click to resend
                      </span>
                    </p>
                    <Button type="submit" className="btn-primary w-100"><i className=""></i>Continue</Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}
